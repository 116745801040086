import React, { Component } from 'react';
import styled from 'styled-components';
import axios from 'axios'; // For API requests
import NextButton from './NextButton';
import t from '../../../lib/i18n';
import { API_HOST_V3 } from "core/src/lib/constants";

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const SuggestionsList = styled.ul`
  position: absolute;
  top: 81px;
  max-width: 400px;
  max-height: 200px; /* Adjust the height as needed */
  overflow-y: auto;
  background-color: white;
  border: 1px solid #ccc;
  border-top: none; /* To avoid double borders with input */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000; /* Ensure the list appears above other elements */
  margin: 0;
  padding: 0;
  list-style: none;
`;
const SuggestionItem = styled.li`
  padding: 10px;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }
`;

export default class QuestionUSPSAddress extends Component {
  constructor(props) {
    super(props);
    this.autocompleteInputRef = React.createRef();
    this.state = {
      value: '',
      unitNumber: '',
      deliveryInstructions: '',
      isValidAddressSelected: false,
      needsCorrection: false,
      clickedNextWithoutValidAddress: false,
    };
  }
  
  handleAddressChange = async (e) => {
    const address = e.target.value;
    this.setState({ value: address, suggestions: [] });
    
    if (address.length > 5) { // Trigger API call after minimum address length met
      try {
        const queryString = `street=${encodeURIComponent(address)}`;
        const url = `${API_HOST_V3}/location/usps_search?${queryString}`;
        const response = await axios.get(url);
        if (response.data.suggestions && response.data.suggestions.length > 0) {
          this.setState({ suggestions: response.data.suggestions, isValidAddressSelected: false });
        } else {
          this.setState({ suggestions: [], isValidAddressSelected: false });
        }
      } catch (error) {
        console.error('API Error:', error);
        this.setState({ isValidAddressSelected: false });
      }
    } else {
      this.setState({ isValidAddressSelected: false });
    }
  };
  
  handleSuggestionClick = async (suggestion) => {
    const fullAddress = `${suggestion.street_line}, ${suggestion.city}, ${suggestion.state} ${suggestion.zipcode}`;
    this.setState({
      value: fullAddress,
      suggestions: [],
      isValidAddressSelected: true,
      usps_meta: null
    });
    
    // Perform a validation check to see if further details are needed
    try {
      const response = await axios.post(`${API_HOST_V3}/location/usps_validation`, { address: fullAddress });
      console.log('response', response.data);
      if (response.data.needs_correction) {
        this.setState({ needsCorrection: true, usps_meta: response.data.meta });
        alert(t('Please enter the unit number for your address.'));
      } else {
        this.setState({ needsCorrection: false, usps_meta: null });
      }
    } catch (error) {
      console.error('Validation Error:', error);
    }
  };
  handleNext = (is_optional = false, keyValue = '') => {
    if (is_optional === true && keyValue !== '') {
      this.props.onSubmit({ key: keyValue, label: 'SKIPPED' })
    }
    else {
      if (this.state.value && this.state.isValidAddressSelected) {
        const submission = {
          key: this.props.step.fields[0].question_id,
          label: {
            address: this.state.value,
            unitNumber: this.state.unitNumber,
            deliveryInstructions: this.state.deliveryInstructions,
            meta: this.state.usps_meta
          }
        };
        this.props.onSubmit(submission);
        this.setState({ value: '', unitNumber: '', deliveryInstructions: '', isValidAddressSelected: false, usps_meta: null });
      }
    }
  };
  
  handleSuggestionClick = (suggestion) => {
    const fullAddress = `${suggestion.street_line}, ${suggestion.city}, ${suggestion.state} ${suggestion.zipcode}`;
    this.setState({
      value: fullAddress,
      suggestions: [],
      isValidAddressSelected: true,
      clickedNextWithoutValidAddress: false 
    });
  };
  
  render() {
    const { value, suggestions, needsCorrection } = this.state;
    return (
      <Wrap>
      <StyledWrapper>
      {(this.state.clickedNextWithoutValidAddress) && <div style={{color: "red"}}>Please select an address from the dropdown list.</div>}
        <input
          style={{ margin: 60, width: '100%', maxWidth: 400 }}
          ref={this.autocompleteInputRef}
          type="text"
          placeholder={t('Enter your address')}
          value={value}
          onChange={this.handleAddressChange}
        />
        {suggestions && suggestions.length > 0 && (
          <SuggestionsList>
            {suggestions.map((suggestion, index) => {
              const fullAddress = `${suggestion.street_line}, ${suggestion.city}, ${suggestion.state} ${suggestion.zipcode}`;
              return (
                <SuggestionItem
                  key={`${suggestion.street_line}-${suggestion.zipcode}-${index}`}
                  onClick={() => this.handleSuggestionClick(suggestion)}
                >
                  {fullAddress}
                </SuggestionItem>
              );
            })}
          </SuggestionsList>
        )}
      </StyledWrapper>
        {this.state.value && (
          <>
            <input
              style={{ margin: 20, marginTop: 0, width: '100%', maxWidth: 400 }}
              type="text"
              placeholder={t('Unit Number')}
              value={this.state.unitNumber}
              onChange={(e) => this.setState({ unitNumber: e.target.value })}
            />
          </>
        )}
        {value && (
          <>
            <input
              style={{ margin: 20, width: '100%', maxWidth: 400 }}
              type="text"
              placeholder={t('Delivery Instructions')}
              value={this.state.deliveryInstructions}
              onChange={(e) => this.setState({ deliveryInstructions: e.target.value })}
            />
          </>
        )}
        <div style={{ position: 'relative', display: 'inline-block' }}>
          <NextButton
            {...this.props}
            onClick={this.handleNext}
            disabled={!this.state.isValidAddressSelected}
            label="Next"
            zIndex={1}
          />
          {!this.state.isValidAddressSelected && (
            <button
              onClick={() => {
                this.setState({ clickedNextWithoutValidAddress: true });
              }}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: '30%',
                backgroundColor: 'transparent',
                border: 'none',
                cursor: 'pointer',
                zIndex: !this.state.isValidAddressSelected ? 5 : 3,
              }}
            />
          )}
        </div>
      </Wrap>
    );
  }
}
